import * as React from 'react'
import {BrowserRouter} from 'react-router-dom'
import {List, getInstance} from '@wf-mfe/lists'
import * as toolbar from '@wf-mfe/lists'
import toolbarDefinitionPlugin, {
  SELECTION_CHANGED_EVENT,
} from './plugins/toolbar/ToolbarDefinitionsPlugin'
import {columns, LIST_ID, metadata} from './table-data'
import DataFetcherPlugin, {DataFetcherOptions} from './plugins/dataFetcher'
import RendererPlugin from './plugins/renderers'
import FilterPlugin from './plugins/toolbar/filters/FilterPlugin'
import {LocalizationProvider} from '@wf-mfe/localize-react'
import {primary} from '@phoenix/all'
import {css} from 'react-emotion'
import {espClient, redrockClientFactory} from '../../shared/L10n'

const ToolbarComponent = toolbar.getToolbarComponent(LIST_ID)

const wrapperDivStyle = css`
  // subtract toolbar height
  height: calc(100% - 41px);
  // Match to the Phoenix <Text/> styles
  .body-inner-cell {
    font-size: 14px;
    color: ${primary.gray()};
  }

  // with line-height: 26px; the rows height become 36px
  .list-body-cell {
    line-height: 26px;
  }

  // align the cell items in the middle of the rows
  .table-body-cell-styled > div {
    display: flex;
    align-items: center;
  }

  // prevent wrapping of header cell texts
  .table-header-cell-styled > div {
    white-space: nowrap;
  }

  // enforce checkbox sizes to 14px and position them in the center of its cell
  div[data-test-id='header-checkbox'],
  div[data-test-id='row-checkbox'] {
    width: 14px;
    height: 14px;
    margin: 0 auto;
  }

  .table_container {
    height: 100%;
    justify-content: space-between;
  }
`

interface ProjectImportListComponentProps extends Partial<DataFetcherOptions> {
  onSelectionChange?: (ids: string[]) => void
}

function RouterWrapper({children}) {
  return <BrowserRouter>{children}</BrowserRouter>
}

const ProjectImportListComponent: React.FC<ProjectImportListComponentProps> = (props) => {
  const plugins = React.useRef([
    {
      importPromise: Promise.resolve(toolbar),
      initOptions: {
        toolbarId: 'projects',
      },
    },
    {
      importPromise: Promise.resolve(toolbarDefinitionPlugin),
    },
    {
      importPromise: Promise.resolve(RendererPlugin),
    },
    {
      importPromise: Promise.resolve(DataFetcherPlugin),
      initOptions: {
        excludeProjectIDs: props.excludeProjectIDs,
        startDate: props.startDate,
        endDate: props.endDate,
      },
    },
    {
      importPromise: Promise.resolve(FilterPlugin),
    },
  ])
  const messages = {
    name: espClient.getTextSync('lists.import.name', 'Name'),
    portfolioPriority: espClient.getTextSync(
      'lists.import.portfolio.priority',
      'Portfolio Priority'
    ),
    'portfolio:name': espClient.getTextSync('lists.import.portfolio', 'Portfolio'),
    'program:name': espClient.getTextSync('lists.import.program', 'Program'),
    'owner:name': espClient.getTextSync('lists.import.owner', 'Owner'),
    status: espClient.getTextSync('lists.import.status', 'Status'),
    percentComplete: espClient.getTextSync('lists.import.percentcomp', '% Complete'),
    plannedStartDate: espClient.getTextSync('lists.import.plannedstartdate.abbr', 'Pln start'),
    plannedCompletionDate: espClient.getTextSync(
      'lists.import.plannedcompletiondate.abbr',
      'Pln Comp'
    ),
  }
  const data = React.useRef({
    metadata: metadata,
    columns: columns().map((item) => {
      return {
        ...item,
        display: item.field ? messages[item.field] : '',
      }
    }),
    collapsedGroups: [],
    groups: {},
    collapsedRows: [],
    messageKeys: ['project.plural', 'project'],
    rows: {},
  })
  const options = React.useRef({
    isInModal: false,
    isReactListToolbarsEnabled: true,
    listDataStore: getInstance(LIST_ID),
    bottomOffset: 0,
    useAvailableHeight: true,
  })

  const projectImportListRef = React.useRef<HTMLDivElement>(null)
  const selectionHandler = React.useCallback(
    (event) => {
      if (props.onSelectionChange) {
        props.onSelectionChange(event.detail.selectedIDs)
      }
    },
    [props]
  )
  React.useLayoutEffect(() => {
    const wrapperElement = projectImportListRef.current!
    wrapperElement.addEventListener(SELECTION_CHANGED_EVENT, selectionHandler, false)
    return () => {
      wrapperElement.removeEventListener(SELECTION_CHANGED_EVENT, selectionHandler, false)
    }
  }, [selectionHandler])

  return (
    <RouterWrapper>
      <div id={LIST_ID} ref={projectImportListRef} className={wrapperDivStyle}>
        <LocalizationProvider clientFactory={redrockClientFactory}>
          <>
            <ToolbarComponent />
            <List data={data.current} options={options.current} plugins={plugins.current} />
          </>
        </LocalizationProvider>
      </div>
    </RouterWrapper>
  )
}

export const ProjectImportList = React.memo<ProjectImportListComponentProps>(
  ProjectImportListComponent
)
ProjectImportListComponent.displayName = 'ProjectImportList'
ProjectImportListComponent.defaultProps = {
  excludeProjectIDs: [],
}
